.gibobs-steps-appraisal {
  .ant-steps-item-description {
    margin-left: 0;
    margin-top: 6px;
    width: auto;
  }
  .ant-steps-item-icon {
    width: 24px !important;
    height: 24px !important;
    background: white;
  }

  .ant-steps-item-process {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-title {
          margin-top: 0;
          font-size: 16px;
          color: #2f4858;
          font-weight: 400;
        }
        .ant-steps-item-description {
          color: #748ea0;
          font-size: 12px;
          font-weight: 400;
          width: 288px;
        }
      }
      .ant-steps-item-icon {
        background: #ffffff;
        .ant-steps-icon {
          color: #02c3cd;
        }
      }
    }
  }
}
.gibobs-steps-appraisal-mobile {
  .ant-steps-item-process {
    .ant-steps-item-container {
      .ant-steps-item-content {
        .ant-steps-item-description {
          width: auto;
        }
      }
    }
  }
}

.title-appraisal {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #2f4858;
}
.title-principal-appraisal {
  font-size: 16px;
  line-height: 24px;
}

.description-principal-appraisal {
  font-size: 14px;
  line-height: 20px;
  color: #748ea0;
}

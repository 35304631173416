.cardButtonStyle {
	background-color: white;
	position: fixed;
	bottom: 0;
	border: none;
	width: 100%;
	justify-content: center;
	box-shadow: 0px -2px 8px rgba(47, 72, 88, 0.1);
	.ant-card-body {
		padding: 16px;
		width: 100%;
	}
	// display: none;
}

.button-primari-gibobs {
	background-color: #02c3cd;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	font-weight: 600;
	width: auto;
}

.button-primari-gibobs:hover {
	background-color: #02c3cd !important ;
}

.button-secundary-gibobs {
	background-color: white;
	border: 1px solid #02c3cd !important;
	border-radius: 4px;
	color: #02c3cd !important;
	width: auto;
	height: 40px;
	font-size: 14px;
	font-weight: 600;
}

.button-primari-gibobs-mobile {
	background-color: #02c3cd !important;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 16px !important;
	text-shadow: none;
	box-shadow: none;
	font-weight: 600;
	width: 100%;
}

.button-secundary-gibobs-mobile {
	background-color: white;
	border: 1px solid #02c3cd !important;
	border-radius: 4px;
	color: #02c3cd;
	width: 100%;
	height: 40px;
	font-size: 16px !important;
	font-weight: 600;
}

.button-primari-delete-gibobs {
	background-color: #ff4d4f;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	border-color: #ff7a7b;

}
.button-primari-delete-gibobs:hover{
	color: #fff !important;
	background: #ff7a7b !important;
	border-color: #ff7a7b !important;
}
.button-primari-delete-gibobs:focus {
	color: #fff!important;
	background: #ff7a7b !important;
	border-color: #ff7a7b!important;
}

.gcardMovile {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 24px;
	}
}

.gcardMovile-16-padding {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 16px;
	}
}

.gb-card-background-blue {
	background-color: #effcfc;
	border: 1px solid #02c3cd;
	border-radius: 4px;
	.ant-card-body {
		padding: 24px;
	}
}

.gb-card-background-blue-mobile {
	background-color: #effcfc;
	border: 1px solid #02c3cd;
	border-radius: 4px;
	.ant-card-body {
		padding: 16px;
	}
}


.gb-modal-16-padding {
	.ant-modal-header {
		padding-top: 32px;
		padding-right: 16px;
		padding-left: 16px;
		border-radius: 4px !important;
	}
	.ant-modal-body {
		padding-top: 0px;
		padding-right: 16px;
		padding-left: 16px;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-footer {
		display: flex;
		
	}
	.ant-modal-close-x {
		padding-top: 16px;
		padding-right: 16px !important;
		color: #02c3cd;
	}
}

.gb-modal-32-padding {
	.ant-modal-header {
		padding-top: 32px;
		padding-right: 32px;
		padding-left: 32px;
	}
	.ant-modal-body {
		padding-top: 0px;
		padding-right: 32px;
		padding-left: 32px;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-footer {
		display: flex;
		padding-bottom: 20px;
	}
	.ant-modal-close-x {
		padding-top: 16px;
		padding-right: 32px !important;
		color: #02c3cd;
	}
}

.gb-modal-40-padding {
	.ant-modal-header {
		padding-top: 40px;
		padding-right: 40px;
		padding-left: 40px;
		border-bottom: none;
	}
	.ant-modal-body {
		padding-top: 0px;
		padding-right: 40px;
		padding-left: 40px;
		
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-footer {
		display: flex;
		padding-bottom: 40px;
		padding-top: 24px;
		border-top: none;
		place-content: center;
	}
	.ant-modal-close-x {
		padding-top: 24px;
		padding-right: 40px !important;
		color: #02c3cd;
	}
}

.gb-card {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
}

.gb-card-24px {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 8px;
	.ant-card-body {
		padding: 24px;
	}
}

.gb-card-16px {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 8px;
	.ant-card-body {
		padding: 0px, 16px, 0px, 16px;
	}
}

.gb-card-0px {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 8px;
	.ant-card-body {
		padding: 0px;
	}
}

.init-card-video-jorge {
	width: calc(100% - 24px);
	margin: auto;
	border-radius: 0px 0px 4px 4px;

	.ant-card-body {
		background: linear-gradient(#0088c6 0%, #00b298 100%);
	}
}

.card-init-client {
	height: 'auto';
	margin-bottom: '100px';
	.init-card {
		margin: auto;
		width: calc(100% - 24px);
		align-items: center;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		height: 100%;
		overflow: hidden;
		border-radius: 4px;
		.title-init-card {
			font-weight: 600;
			font-size: 14px;
			color: #2f4858;
		}
		.sub-title-productos {
			font-weight: 600;
			font-size: 14px;
			color: #02c3cd;
			margin-top: 24px;
			min-height: 50px;
		}
		.description-product {
			font-weight: 400;
			font-size: 12px;
			color: #748ea0;
			margin-top: 24px;
			margin-bottom: 25px;
			//height: 150px;
		}
		.card-bottom {
			width: 100%;
			height: 24px;
			border-top: none;
		}
		.ant-card-actions {
			border-top: none;
		}
	}
	.init-card-mobile {
		margin: auto;
		width: calc(100% - 24px);
		align-items: center;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		overflow: hidden;

		.title-init-card {
			font-weight: 600;
			font-size: 14px;
			color: #2f4858;
		}
		.ant-card-body {
			padding: 24px !important;
		}

		.sub-title-productos {
			font-weight: 600;
			font-size: 14px;
			color: #02c3cd;
			margin-top: 24px;
			min-height: 50px;
		}
		.description-product {
			font-weight: 400;
			font-size: 12px;
			color: #748ea0;
			margin-top: 24px;
			margin-bottom: 25px;
			//height: 150px;
		}
		.card-bottom {
			width: 100%;
			height: 24px;
			border-top: none;
		}
		.ant-card-actions {
			border-top: none;
		}
		.gb-card-loan-init {
			.ant-card-body {
				padding: 16px !important;
				border: 1px solid #52acff !important;
				border-radius: 4px !important;
				background: #d4eaff !important;
			}
		}
	}
}

.title-card {
	font-size: 16px;
	font-weight: 600;
	color: #2f4858;
}

.gb-drawer-32px {
	.ant-drawer.ant-drawer-open .ant-drawer-mask {
		width: 400px !important;

		.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
			width: 400px !important;
		}
	}

	.ant-drawer-header {
		padding: 32px;
		border-bottom: none;
	}
	.ant-drawer-header-title {
		flex-direction: row-reverse;
	}
	.ant-drawer-content {
		overflow-x: hidden !important;
	}
	.ant-drawer-body {
		padding: 32px;
		margin-top: -30px;
	}
	.ant-drawer-close {
		margin-top: 0px;
		color: #2f4858;
		margin-right: -10px;
	}
	.ant-drawer-footer {
		visibility: hidden;
	}
}
.StepsFormalization {
	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			.ant-steps-icon {
				display: inline-flex;
			}
		}
	}
	.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #c0daee;
	}
}

.ModalCommon-movile-24px {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}
	.ant-modal-header {
		border-bottom: none;
		padding-left: 24px;
		padding-top: 24px;
		padding-bottom: 24px;
		padding-right: 24px;
	}
	.ant-modal-body {
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 0px;
	}
	.ant-modal-footer {
		padding-top: 0;
		padding-right: 24px;
		padding-bottom: 24px;
		border-top: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 10px;
		padding-right: 24px;
	}
}

.ModalCommon-movile-32px {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}
	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 32px;
		padding-right: 32px;
	}
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 0px;
	}
	.ant-modal-footer {
		padding-top: 0;
		padding-right: 32px;
		padding-bottom: 32px;
		border-top: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 10px;
		padding-right: 24px;
	}
}

.button-primary-gibobs-inmo {
	background-color: #02c3cd;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	font-weight: 600;
	width: auto;
}
.button-mobile-typography {
	font-size: 16px;
}

.button-secundary-gibobs-inmo {
	background-color: white;
	border: 1px solid #02c3cd !important;
	border-radius: 4px;
	color: #02c3cd;
	width: auto;
	height: 40px;
	font-size: 14px;
	font-weight: 600;
}

.gb-form-common-new-style {
	.ant-form-item {
		margin-bottom: 40px;
		.ant-form-item-label {
			line-height: 16px;
			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
			}
		}
		.ant-form-item-control {
			input {
				height: 40px;
				color: #748ea0;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
			}
			.ant-radio-wrapper {
				color: #2f4858;
				font-size: 12px;
				font-weight: 400;
			}
			.ant-checkbox-group {
				label {
					color: #748ea0;
					font-size: 14px;
					font-weight: 400;
				}
			}
			.ant-radio-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}
			.ant-form-item-explain-error {
				font-size: 12px;
				margin-top: 8px;
			}
			.ant-form-item-control-input-content {
				textarea {
					color: #748ea0;
					font-size: 14px !important;
				}
			}

			.ant-select-selector {
				height: 40px;
				width: 100%;
				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #748ea0;
				}
				.ant-select-selection-placeholder {
					height: 40px;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}

.check-box-icon-green {
	display: flex;
	justify-content: space-between;
	width: 300px;
}

.card-viability-data-Mobile {
	background-color: #fafefe;
	border-color: #c0f0f3;
	width: 592px;
	margin-right: 196px;
	.ant-card-body {
		padding: 16px !important;
	}
}
.documents-list-row-completed {
	background-color: #effcfc !important;
	color: #748ea0;
}

.card-no-padding {
	.ant-card-body {
		padding: 0px !important;
	}
}

.init-card-banner-amigobs-desktop {
	width: calc(100% - 50px);
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	max-height: auto !important;
	overflow: hidden;
	background-size: cover;
	border-radius: 4px;
	border-bottom-left-radius: 7px !important;
	border-bottom-right-radius: 7px !important;

	margin-left: 40px;

	.ant-card-body {
		padding: 0px;
		height: 72px;
	}

	.title-init-card {
		font-weight: 600;
		font-size: 14px;
		color: #2f4858;
	}
	.sub-title-productos {
		font-weight: 600;
		font-size: 14px;
		color: #02c3cd;
		margin-top: 24px;
		min-height: 50px;
	}
	.description-product {
		font-weight: 400;
		font-size: 12px;
		color: #748ea0;
		margin-top: 24px;
		margin-bottom: 25px;
		//height: 150px;
	}
	.card-bottom {
		width: 100%;
		height: 24px;
		border-top: none;
	}
	.ant-card-actions {
		border-top: none;
	}
}
.ant-slider-track {
	background-color: #02c3cd !important;
}

.StepsMobileAppraisal {
	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;
			.ant-steps-icon {
				display: inline-flex;
				color: #52c41a;
			}
		}
	}
	.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #c0daee;
	}
	.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
		background: #c0daee;
	}
}

.ant-slider-rail {
	background-color: #c0daee;
}

.doc-view-modal{
	#pdf-download{
	display: none !important;
	}
} 



.title-primary {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
}

.paragraph-style {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #748ea0;
}

.paragraph-style-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
}

.paragraph-style-mobile-second {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #2F4858;
}

.radio-gibobs-promoter {
	margin-bottom: 16px;

	.ant-radio-wrapper {
		color: #2f4858;
		font-size: 14px;
		font-weight: 400;
		margin-right: 76px;
	}
}

.sub-title-promoter-gibobs {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}

.sub-title-promoter-gibobs-mobile {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #2f4858;
}

.card-data-project {
	background: #effcfc;
	border: 1px solid #c0f0f3;
	border-radius: 4px;
}

.title-project-data {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}

.paragraph-project-data {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
}

.collapse-gibobs-promoter {
	.ant-collapse-item {
		.ant-collapse-header {
			padding-left: 0px;
			border-bottom: 1px solid #c0daee;
		}

		.ant-collapse-content {
			.ant-collapse-content-box {
				padding: 0px;
				margin-top: 16px;
			}
		}
	}
}

.modal-promoter {
	.ant-modal-header {
		padding: 0px !important;
	}

	.ant-modal-footer {
		text-align: center;
	}
}

.modal-promoter-header {
	.ant-modal-header {
		padding-bottom: 8px !important;
	}
}

.modal-promoter-mobile {
	.ant-modal-content {
		border-radius: 4px;
		width: calc(100vw - 16px*2);
		left: 50%;
		transform: translateX(-50%);
	}

	.ant-modal-body {
		padding: 32px !important;
	}

	.ant-modal-close-x {
		padding-top: 18px !important;
		padding-right: 32px !important;
	}

}

.modal-promoter-share-mobile {
	.ant-modal-close {
		padding-top: 8px !important;
	}
}

.gibobs-steps-financing-promoter {
	overflow-y: scroll;

	.ant-steps-item-description {
		margin-left: 0;
		margin-top: 6px;
		width: auto;
	}

	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
		background: white;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #02c3cd;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #2f4858;
					line-height: 24px;
					font-weight: 400;
				}

				.ant-steps-item-description {
					font-weight: 400;
					color: #748ea0;
					font-size: 14px;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;
			border: 1px solid #02c3cd;

			.ant-steps-icon {
				display: initial;
				color: #02c3cd;
			}
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail {
				color: red;
				display: none;
			}

			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #2f4858;
				}
			}
		}

		.ant-steps-item-icon {
			border-color: #52c41a;
			background-color: #ffffff;

			.ant-steps-icon {
				top: -1.5px;
				display: inline;
				color: #52c41a;
			}
		}
	}
}

.gibobs-steps-financing-promoter::-webkit-scrollbar {
	width: 12px;
	background-color: transparent;
}

.gibobs-steps-financing-promoter::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 50px;
	background-color: transparent;
}

.gb-steps-promoter {
	// margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
		margin-top: 40px;
	}

	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;
			margin-left: 18px;

			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
					margin-left: -48px;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 4px solid #02c3cd;
				margin-left: 10px;
				margin-right: 10px;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				text-align: center !important;
				margin-left: -48px;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
			margin-left: 10px;
			margin-right: 5px;
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
					margin-left: -50px;
				}
			}
		}
	}
}

.modal-promoter-footer {
	.ant-modal-footer {
		text-align: center;
	}
}

.dots-class {
	width: 8px;
	height: 8px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #f1f7f8;
}

.dots-class-select {
	width: 8px;
	height: 8px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #02c3cd;
}

.container-dots {
	display: flex;
	column-gap: 8px;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
}

.textStyleTableOfferSelect {
	background-color: #f1f7f8;

	.ant-table-cell-row:hover {
		background: #f1f7f8 !important;
	}
}

.textStyleTable {
	font-size: 12px;
	color: #2f4858;
}

.textStyleTotal {
	font-size: 14px;
	font-weight: 500;
}

.textStyleTotalColor {
	background-color: #effcfc;
}

.table-style-general {
	border: 1px solid #c0daee;

	.ant-table-content {
		margin-left: 0;
	}
}

.init-card-promoter {
	margin: auto;
	width: 100%;
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	height: 100%;
	overflow: hidden;

	.title-init-card {
		font-weight: 600;
		font-size: 16px;
		color: #2f4858;
	}

	.sub-title-productos {
		font-weight: 600;
		font-size: 14px;
		color: #02c3cd;
		margin-top: 24px;
		min-height: 50px;
	}

	.description-product {
		font-weight: 400;
		font-size: 14px;
		color: #748ea0;
		margin-top: 24px;
		margin-bottom: 25px;
		//height: 150px;
	}

	.card-bottom {
		width: 100%;
		height: 24px;
		border-top: none;
	}

	.ant-card-actions {
		border-top: none;
	}
}

// .init-card-video-promoter {
//   width: calc(100% - 24px);
//   height: calc(100% - 330px);
//   margin: auto;
//   border-radius: 4px;
//   margin-top: 40px;
//   margin-bottom: 24px;
//   box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
//   background: #c0f0f3;

//   // .ant-card-body {
//   //   padding: 24px !important;
//   // }

//   .ant-card-body {
//     background: #c0f0f3;
//     // color: #2f4858;
//   }
// }

.radio-gibobs-promoter-b {
	margin-bottom: 16px;

	.ant-radio-wrapper {
		color: #2f4858;
		font-size: 14px;
		font-weight: 400;
	}
}

.update-scenarios-styles {
	display: flex;
	background-color: #ffdede;
	box-shadow: rgb(47 72 88 / 10%) 0px 0px 8px;
	border-radius: 4px;
	padding: 16px;
	border: 1px solid #ff4d4f;
	justify-content: flex-start;
	margin-top: 32px;
	margin-bottom: 10px;
}

.text-detail-small {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #bf3a3b;
}

.card-promoter-welcome {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
	margin-bottom: 100px;

	.ant-card-body {
		padding: 24px;
	}
}

.form-promoter-mobile {
	margin-top: 16px;
	margin-bottom: 140px;

	.ant-form-item {
		.ant-input {
			height: 40px;
			display: flex;
			align-items: center;
			border-radius: 8px;
		}

		.ant-input-number {
			//height: 40px;
			display: flex;
			align-items: center;
			border-radius: 8px;
		}

		.ant-select-selector {
			height: 40px !important;
			display: flex;
			align-items: center;
			border-radius: 8px !important;
		}

		textarea.ant-input {
			height: 76px;
			padding: 10px 12px;
			border-radius: 8px;
		}

		label {
			color: #2f4858;
			font-weight: 400;
			font-size: 14px;
		}
	}

	.ant-input-number-disabled {
		color: #748EA0;
	}
}

.progress-by-steps-promoter-mobile {
	display: flex;
	justify-content: center;

	.steps-and-title {
		flex-direction: column;
		text-align: center;
	}

	.ant-progress-steps-outer {
		gap: 8px;
		margin-top: -10px;
	}

	.ant-progress-steps-item {
		min-width: 36px;
		margin-right: 0px;
	}
}

.card-promoter-revision {
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	background: var(--support-info-l-2, #d4eaff);
	padding: 0px !important;

	.ant-card-body {
		padding: 16px !important;
	}
}

.button-gibobs-promoter {
	border-radius: 8px;
	border: 1px solid var(--primary-original, #02c3cd);
	background: var(--primary-l-3, white);
	width: 104px;
	height: 48px;
	color: #02c3cd;
	font-size: 16px;
	font-weight: 600;
}

.button-gibobs-promoter2 {
	border-radius: 8px;
	border: 1px solid var(--primary-original, #02c3cd);
	background: var(--primary-l-3, #EFFCFC) !important;
	width: 104px;
	height: 48px;
	color: #02c3cd;
	font-size: 16px;
	font-weight: 600;
}

.notification-success-promoter {
	border: 1px solid #52C41A;
	border-radius: 8px;
	top: 40px !important;
	background-color: #DFF4D4;
	max-width: calc(100vw - 16px*2) !important;

	.ant-notification-notice-message {
		font-size: 16px;
		font-weight: 600;
		color: #3E9314 !important;
		margin: 0 !important;
		padding: 0 !important;
		margin-left: 36px !important;
	}

	.ant-notification-notice-close-x {
		display: none !important;
	}

	.ant-notification-notice-description {
		font-size: 14px;
		font-weight: 400;
		color: #2F4858;
		padding: 0 !important;
		margin: 0 !important;
		margin-left: 36px !important;
	}
}

.modal-description-mobile-promoter {
	.ant-modal-content {
		border-radius: 4px;
	}

	.ant-modal-header {
		border: none;
	}

	.ant-modal-footer {
		border: none;
	}
}

.help-button-modal-mobile {
	.ant-modal-header {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-bottom: none;
	}

	.ant-modal-content {
		border-radius: 8px;
	}

}

.upload-mobile {
	.ant-upload.ant-upload-select.ant-upload-select-text {
		width: 100%;
	}
}

.input-financial-data-error-mobile {

	.ant-form-item-explain,
	.ant-form-item-extra {
		line-height: 1.2 !important;
	}
}

.gb-card-10px {
	.ant-card-body {
		padding: 10px !important;
	}
}

.viabilityZero {
	.ant-alert-description {
		font-family: Inter;
		font-size: 16px;
		font-weight: 600;
		color: #BC820F;
	}
}

.ModalInfoFunding {
	.ant-modal-content {
		padding: 0px;
	}

	.ant-modal-body {
		padding: 0px;
		border-radius: 4px;
	}
	
	.ant-modal-close-x {
		color: #fff;
		font-size: 24px;
		padding-top: 12px;
		padding-right: 12px;
	}
}
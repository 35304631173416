.modal-mobile-basic {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}
	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 8px;
		padding-right: 32px;
	}
	.ant-modal-body {
		padding: 0 32px 32px;
		font-size: 14px;
		color: #748ea0;
	}
	.ant-modal-footer {
		padding: 0 32px 32px;
		border-top: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 12px;
		padding-right: 28px;
	}
}

.modal-basic {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}
	.ant-modal-header {
		border-bottom: none;
		padding: 40px 40px 8px 40px;
	}
	.ant-modal-body {
		padding: 0px 40px 38px 40px;
		font-size: 14px;
		color: #748ea0;
	}
	.ant-modal-footer {
		padding: 0px 40px 40px;
		border-top: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.modal-after-banks-mobile {
	top: 20px !important;

	.ant-modal-body {
		padding: 0px;
	}
	.ant-modal-content {
		border-radius: 4px;
		padding: -16px;
	}

	.ant-modal-footer {
		padding-right: 32px;
		padding-top: 32px;
		width: 100%;
		margin-top: -25px;
		text-align: center;
	}
}

.init-card-apps-mobile {
	width: 100% !important;
	align-items: center;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	height: 60%;
	overflow: hidden;
	margin-left: 0 !important;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1000;
	max-height: 100% !important;
}
.modal-footer-align {
	.ant-modal-footer {
		text-align: center;
	}
}

.reviews-styles {
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-decoration-line: underline;
	color: #02c3cd;
	justify-content: center;
}
.rating-styles {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #748ea0;
}
.customer-feedback {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #748ea0;
}

.reviews-analyst-styles {
	background-color: #f1f7f8;
	padding: 8px;
	margin-top: 16px;
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
}

.analyst-name-style {
	font-size: 16px;
	color: #2f4858;
	line-height: 24px;
	text-align: left;
	display: flex;
	justify-content: center;
	font-weight: 600;
}
.analyst-phone-email-style {
	font-size: 14px;
	color: rgb(47, 72, 88);
	font-weight: 500;
	line-height: 20px;
	border: none;
}

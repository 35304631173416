.modal-help-gibobs {
  .ant-modal-title {
    font-size: 14px;
    font-weight: 600;
    color: #2f4858;
  }
  .ant-modal-header {
    display: none;
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;
    height: 300px;
  }
  .ant-modal-footer {
    display: none;
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
}

.title-help {
  color: #02c3cd;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.afrodita {
  &.gb-input-number {
    width: 100%;
    
    .ant-input-number-input-wrap::after {
      text-align: right;
      position: absolute;
      right: 10px;
      top: 1px;
      height: 100%;
      width: 100%;
      vertical-align: middle;
      pointer-events: none;
      display: inline-block;
      line-height: 40px;
      color: #757575;
      content: attr(gb-suffix-input);
    }
  }
  &.gb-input-new-kw {
    .ant-input-number-input-wrap::after {
      content: 'kw';
   }
  }
  &.gb-input-new-kg {
    .ant-input-number-input-wrap::after {
      content: 'kg';
   }
  }
  &.gb-input-new-percent {
    .ant-input-number-input-wrap::after {
      content: '%';
   }
  }
  &.gb-input-new-years-euro{
    .ant-input-number-input-wrap::after {
      content: '€/año';
   }
  }
  &.gb-input-new-netincomes{
    .ant-input-number-input-wrap::after {
      content: '€/mes';
   }
  }
  
  &.gb-input-new-quote {
    .ant-input-number-input-wrap::after {
      content: 'cuotas';
   }
  }

  &.gb-input-new-years {
    .ant-input-number-input-wrap::after {
      content: 'años';
   }
  }

  &.gb-input-number-money {
    .ant-input-number-input-wrap::after {
       content: '€';
    }
    
  }
 
}
@primary-color: #02C3CD;@layout-body-background: #FAFEFE;@select-item-selected: white;
.attach-document-text {
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-decoration-line: underline;
	color: @primary-color;
	cursor: pointer;
}

.wrapper-flex {
	display: flex;
	align-items: center;
}

.gb-card-docs-not-up {
	background: #ffffff;
	border: 1px solid #ffdede;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 16px 8px;
	}
}

.gb-card-docs-up {
	background: #ffffff;
	border: 1px solid #f1f7f8;
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-card-body {
		padding: 16px 8px;
	}
}

.render-card-name {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #2f4858;
}

.subtitle-aditionals-documents {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #748ea0;
}

.buttons-automatic-doc {
	background: #effcfc;
	padding: 12px 16px;
	border: 2px solid @primary-color;
	border-radius: 4px;
	height: 100%;
}

.wrapper-icons-render-cards {
	height: 21px;
	width: 21px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalbankflip {
	.ant-modal-body {
		padding: 0px;
	}

	.ant-modal-content {
		border-radius: 4px;
		padding: -16px;
	}

	.ant-modal-footer {
		display: none;
	}
}

.documents-title-analysis {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #02c3cd;
}

.documents-title-banks {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #0088C6;
}

.our-information-styles {
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #748ea0;
}

@media only screen and (max-width: 750px) {
	.documents-title-analysis {
		font-size: 16px;
	}

	.our-information-styles {
		font-size: 11px;
		margin-bottom: 8px;
	}
}

.tabs-styles-documents {
	color: #2f4858;
	font-style: normal;
	font-weight: normal;
	background: #ffffff;
	margin-top: undefined;
}

.steps-new-style-documents {
	.ant-steps-item-description {
		margin-left: 0;
		margin-top: 6px;
		width: auto;
	}

	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
		background: white;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #05C69A;
				}

				.ant-steps-item-description {
					color: #2F4858 !important;
					font-size: 14px;
					font-weight: 400;
					text-align: left !important;
				}
			}

			.ant-steps-item-tail::after {
				background-color: #05C69A !important;
			}
		}

		.ant-steps-item-icon {
			background: white;
			border-color: #05C69A;
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #c0daee !important;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail::after {
				display: none;
			}

			.ant-steps-item-tail {
				color: red;
				display: none;
			}

			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #2f4858;
				}
			}
		}

		.ant-steps-item-icon {
			border-color: #2F4858;
			background-color: #ffffff;

			.ant-steps-icon {
				top: -1.5px;
				display: inline;
				color: #2F4858;
			}
		}
	}
}

.icon-plus-render-cards {
	height: 16px;
	width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 4px;
}

.buttons-automatic-doc-disabled {
	background: #f1f7f8;
	padding: 12px 16px;
	border: 2px solid #c0daee;
	border-radius: 4px;
	height: 100%;
}

.sigCanvas {
	border: 1px solid black;
}

.sigImage {
	display: block;
	max-width: 50%;
	min-height: 50px;
	margin: 0 auto;
	border: 1px solid black;
	padding: 2rem;
}

.owner-title-documentation {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
	margin-left: 16px;
}

.need-documents-title {
	color: #748ea0;
	margin-bottom: 40px;
	margin-top: 24px;
}

.button-error-afterbanks {
	border: none !important;
	color: @primary-color;
	width: 96%;
	box-shadow: none;
}

.title-error-afterbanks {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #2f4858;
	margin-top: 16px;
}

.sub-title-error-afterbanks {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #748ea0;
	margin-top: 8px;
}

.tab-owner {
	color: #02c3cd;
	font-size: 16px;
	font-weight: 600;
}

.delete-document {
	color: #ff4d4f;
	font-size: 14px;

	.delete-document-text {
		color: #ff4d4f;
		font-size: 14px;
		font-weight: 400;
	}
}

.see-document {
	color: #02c3cd;
	font-size: 14px;

	.see-document-text {
		color: #02c3cd;
		font-size: 14px;
		font-weight: 400;
	}
}

.gb-badget {
	.ant-badge-dot {
		background: #ff4d4f;
	}
}

.gb-check {
	color: #52c41a;
	font-size: 10px;
}

.gb-tabpane {
	background: white;
}

.title-information {
	font-weight: 500;
	font-size: 16px;
	color: #2f4858;
}

.title-information-disabled {
	font-weight: 500;
	font-size: 16px;
	color: #c0daee;
}

.gb-collapse-oferts {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1) !important;

	.ant-collapse {
		border-color: #ECF7FC !important;
		background-color: #ECF7FC !important;
		border: none !important;
	}

	.ant-collapse-header {
		border-color: #ECF7FC !important;
		background-color: #ECF7FC !important;

	}

	.ant-collapse-content {
		background-color: #ECF7FC !important;

	}
}

.gb-collapse-appraisal {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1) !important;

	.ant-collapse {
		border-color: #B8DEEF !important;
		background-color: #B8DEEF !important;
		border: none !important;
	}

	.ant-collapse-header {
		border-color: #B8DEEF !important;
		background-color: #B8DEEF !important;

	}

	.ant-collapse-content {
		background-color: #B8DEEF !important;
		border-top: none;

	}

}

.gb-card-banks-onboarding {
	.ant-card-body {
		padding-top: 8px;
		padding-right: 16px;
		padding-bottom: 8px;
		padding-left: 16px;

	}

	background-color: #B8DEEF;
	border-color: #B8DEEF;
	border-radius: 8px;
}


.gb-card-appraisal {
	.ant-card-body {
		padding-top: 8px;
		padding-right: 16px;
		padding-bottom: 8px;
		padding-left: 16px;

	}

	background-color: white;
	border-color: white;
	border-radius: 8px;
}

.input-sign {
	margin-bottom: 0px;
}

.select-sign {
	margin-bottom: 0px;
	.ant-select-arrow {
		margin-top: -4px !important;
	}
}



.ModalDesktopCert {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
		font-family: 'Inter';
		width: auto;
	}
	.ant-modal-header {
		border-bottom: none;
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
	}
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		width: 100%;
	}
	.ant-modal-footer {
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 32px;
		padding-top: 32px;
		display: flex;
		width: 100%;
		margin-top: -25px;
		border-top: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-right: 20px;
		padding-top: 14px;
	}
}

.security {
	.ant-collapse-header {
		padding: 0px !important;
	}
	.ant-collapse-content-box {
		padding-bottom: 0px !important;
	}
	.ant-collapse-header-text {
		color: #02C3CD !important;
	}
	.ant-collapse-arrow {
		display: none !important;
	}
}

.ModalNextStep {
	.ant-modal-header {
		padding-top: 32px !important;
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 16px !important;
	}
	.ant-modal-body {
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 8px !important;
	}
	.ant-modal-footer {
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 32px !important;
		padding-top: 0 !important;
		text-align: center;
		.ant-btn {
			margin: 0 !important;
			width: auto !important;
		}
	}
}
.ModalNextStepMobile{
	.ant-modal-header {
		padding-top: 32px !important;
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 8px !important;
	}
	.ant-modal-body {
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 0px !important;
	}
	.ant-modal-footer {
		padding-left: 32px !important;
		padding-right: 32px !important;
		padding-bottom: 32px !important;
		padding-top: 0px !important;
		text-align: center;
		.ant-btn {
			margin: 0 !important;
			width: 100%;
		}
	}
}

.DragAndDrop {
  font-family: "Inter";
  text-align: center;
  width: 100%;
  height: auto;
  /* padding: 10px; */
  margin-bottom: 100px;
}

.box {
  position: relative;
  margin-left: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
  float: left;
  color: black;
  background: white;
  box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
  -webkit-box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
  -moz-box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
}

.box .ant-card-body {
  height: 100%;
}

.notication-widget {
  float: right;
  margin-right: 7px;
  margin-top: 5px;
}

.card-calculator-amortization {
	background: #effcfc;
	border: 1px solid #c0f0f3;
	border-radius: 0px 0px 4px 4px;
	.ant-card-body {
		padding: 24px;
	}
}
.card-amortization-title {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #02c3cd;
}
.savings-title {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #2f4858;
}
.savings-sub-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #02c3cd;
}
.gb-progress {
	.ant-progress-outer {
		height: 6px;
	}
	.ant-progress-inner {
		height: 6px;
	}
	.ant-progress-bg {
		height: 6px !important;
	}
}

.card-amortization-second {
	border: 1px solid #02c3cd;
	border-radius: 8px;
	width: 100%;

	.ant-card-body {
		padding: 24px;
	}
}

.card-financing-second {
	border: 1px solid #C0DAEE;
	border-radius: 4px;
	width: 100%;
	background: #F1F7F8;

	.ant-card-body {
		padding: 24px;
	}
}

.wrapper-want-amortize {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	align-items: center;
	.title {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		align-items: center;
		color: #02c3cd;
	}
	.icon {
		color: #02c3cd;
		font-size: 18;
		margin-left: 8px;
	}
}

.modal-want-amortize {
	color: #748ea0;
	font-size: 14px;
	font-weight: 400;
}
.modal-want-amortize-title {
	color: #2F4858;
	font-size: 14px;
	font-weight: 500;
}

.title-subrogation-calculators {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #748ea0;
}

.description-subrogation-calculators {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #02c3cd;
	margin-top: 8px;
}

.card-your-quote-calculators {
	background: #effcfc;
	border-radius: 8px;
	width: 100%;
	border: none;
}
.card-savings-amortization-calculators {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
	width: 100%;
	border: none;
}
.card-save-data-calculators {
	background: #d4eaff;
	border: 1px solid #1890ff;
	border-radius: 4px;
	width: 100%;
	.ant-card-body {
		padding: 16px !important;
	}
	.wrapper-save-data {
		display: flex;
		column-gap: 8px;
		align-items: center;
	}
	.title {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #2f4858;
	}
	.wrapper-title-icon {
		display: flex;
		margin-top: 8px;
    width: 100%;
    justify-content: right;
		.sub-title {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #126cbf;
		}
	}
}
.card-messages-calculators {
	border: 1px solid #c0daee;
	border-radius: 4px;
	width: 100%;
	.title {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #2f4858;
		margin-bottom: 8px;
	}
	.description {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #748ea0;
	}
}
.description-estimated-costs {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
	margin-bottom: 12px;
}

.title-current-mortgage-conditions {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
}
.edit-data-calculators-subrogation {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #02c3cd;
	display: flex;
	align-items: center;
	justify-content: right;
	margin-bottom: 16px;
}

.current-euribor-calculator {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #016b71;
	float: left;
}

.ModalMobileFooterColum {
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #2f4858;
    font-family: "Inter";
    width: 216px;
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .ant-modal-body {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: -30px;
  }
  .ant-modal-footer {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
    display: flex;
    width: 100%;
    margin-top: 0px;
    border-top: none;
    flex-direction: column-reverse;
  }
  .ant-modal-close-x {
    color: #02c3cd;
    padding: 16px;
  }
}

.ModalMobileCert {
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #2f4858;
    font-family: "Inter";
    width: 216px;
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .ant-modal-body {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: -30px;
  }
  .ant-modal-footer {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    padding-top: 32px;
    display: flex;
    width: 100%;
    margin-top: -25px;
    border-top: none;
  }
  .ant-modal-close-x {
    color: #02c3cd;
    padding: 16px;
  }
}

.modal-documents-movile {
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #2f4858;
  }
  .ant-modal-header {
    border-bottom: none;
    padding-left: 32px;
    padding-top: 32px;
    padding-bottom: 8px;
    padding-right: 32px;
  }
  .ant-modal-body {
    border-bottom: none;
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-right: 32px;
  }
  .ant-modal-footer {
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: 40px;
    border-top: none;
    display: flex;
  }
  .ant-modal-close-x {
    margin-top: 15px;
  }
}
.modalMobileAfterBanks {
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 4px;
    padding: -16px;
  }

  .ant-modal-footer {
    padding-right: 32px;
    padding-top: 32px;
    width: 100%;
    margin-top: -25px;
    text-align: center;
  }
}


.modalDesktopAfterBanks {
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-content {
    border-radius: 4px;
    padding: -16px;
    width: 480px;
  }

  .ant-modal-footer {
    padding-right: 32px;
    padding-top: 32px;
    width: 100%;
    margin-top: -25px;
    text-align: center;
  }
}
.ModalDesktopFooterColum {
  .ant-modal-content {
    border-radius: 4px !important;
  }
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #2f4858;
    font-family: "Inter";
    width: auto;
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 4px !important;
  }
  .ant-modal-body {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 20px;
  }
  .ant-modal-footer {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    border-top: none;
    margin-top: -5px;
    border-bottom: none;
  }
  .ant-modal-close-x {
    color: #02c3cd;
    padding-top: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
}

.card-legend {
  .ant-card-head {
    padding: 8px 24px 8px 24px;
  }
  .ant-card-body {
    padding: 8px 24px 8px 24px;
  }
}

.StepMobileDocs {
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 8px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 10px;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 10px;
  }
}

.gb-certificate-step {
  .ant-steps-item-icon {
    width: 24px !important;
    height: 24px !important;
    background: white;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      .ant-steps-icon {
        display: inline-flex;
        background: white;
        color: #52c41a !important;
      }
      border-color: #52c41a !important;
      background: none;
    }
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    width: 190px !important;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    width: 100% !important;
  }
  .ant-steps-item-error .ant-steps-item-icon {
    border-color: #c0daee;
  }

  .ant-steps-item-tail::after {
    margin-top: -2px;
    margin-left: -2px;
    width: 2px;
    height: 100%;
  }
}

.description-card-grey {
  font-size: 14px;
  font-weight: 400;
  color: #748ea0;
  font-family: "Inter";
}

.step-desktop-docs {
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 8px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 8px;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    margin-top: 10px;
  }
}

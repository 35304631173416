.Progress-by-steps {
	display: flex;
	justify-content: center;

	.steps-and-title {
		flex-direction: column;
		row-gap: 12px;
		margin-top: 2px;
		.steps-and-title-text {
			color: #02C3CD;
			font-weight: 600px;
			font-size: 14;
			line-height: 24px;
		}
	}

	.steps-mobile {
		.ant-progress-steps-outer {
			margin-right: -12px;
		}

		.ant-progress-steps-item {
			min-width: 32px;
			margin-right: 12px;
		}
	}
	.steps-desktop {
		.ant-progress-steps-outer {
			margin-right: -16px;
		}
		.ant-progress-steps-item {
			min-width: 60px;
			margin-right: 16px;
		}
	}
}

.applicant-style {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
	padding: 12px 16px;
	padding-top: 0px;

}
.offer-style {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #748ea0;
}

.logo-onboarding {
	text-align: center;
	padding: 24px 0px;
}

.title-stepper-component {
	display: flex;
	text-align: center;
	justify-content: center;
	margin: 24px 0px;
	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #2f4858;
		margin-right: 16px;
		display: flex;
		align-items: center;
	}
}

.modal-onboarding-stepper {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
		width: 95%;
	}
	.ant-modal-header {
		border-bottom: none;
		padding: 32px 32px 0px 32px;
		border-radius: 4px;
	}
	.ant-modal-body {
		padding: 8px 32px 32px 32px;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #748ea0;
	}
	.ant-modal-footer {
		padding: 0px;
		border-top: none;
		border-radius: 4px;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 14px;
		padding-right: 38px;
	}
}

.desktop-background {
	font-family: 'Inter';
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	background-image: url(../../../../assets/onboarding/background.svg);
	background-repeat: no-repeat;
	overflow-y: scroll;
	height: 100%;
	margin-top: 2px;
	// padding-bottom: 10%;
}
.desktop-background::-webkit-scrollbar {
	width: 1px;
}
.desktop-background::-webkit-scrollbar-track {
	background: transparent;
	width: 1px;
}
.desktop-background::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-radius: 6px;
}

.welcome-page {
	align-self: center;
	margin: auto;
	padding: 0px 16px;
	font-family: 'Inter';

	.name {
		color: #02c3cd;
		font-weight: 600;
		margin-left: 4px;
		font-size: 16px;
		line-height: 24px;
	}

	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #2f4858;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
	}

	.description {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #2f4858;
	}

	.operation-options {
		width: 304px;
		height: 166px;
		left: 28px;
		top: 424px;
		background-image: url(../../../../assets/onboarding/mortgage.png);
		border-radius: 4px;
		opacity: 30%;
		background-repeat: round;
	}
	.detail-operation {
		width: 240px;
		height: 86px;

		/* Neutral / White */

		background: #ffffff;
		border-radius: 4px;
	}

	.caja-externa {
		height: 145px;
		cursor: pointer;
	}
	.background-mortgage {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
			url(../../../../assets/onboarding/mortgage.png);
		background-repeat: no-repeat;
		border-radius: 4px;
		background-size: cover;
		background-position: center;
	}
	.background-subrogation {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
			url(../../../../assets/onboarding/subrogation.png);
		background-repeat: no-repeat;
		border-radius: 4px;
		background-size: cover;
		background-position: center;
	}

	.caja-interna {
		width: 240px;
		height: 110px;
		background: #ffffff;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.centrar-contenido {
		display: grid;
		align-content: center;
		justify-content: center;
	}
	.wrapper {
		display: flex;
		margin-top: 13px;
	}

	.text {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		color: #02c3cd;
		margin-right: 6px;
		font-style: normal;
	}
}

.card-button-style-onboading {
	background-color: white;
	position: fixed;
	bottom: 0;
	border: none;
	width: 100%;
	justify-content: center;
	box-shadow: 0px -2px 8px rgba(47, 72, 88, 0.1);
	left: 0;
	margin-bottom: -2px;
	background-color: #fafefe;

	.ant-card-body {
		padding: 16px;
		width: 100%;
		display: flex;
	}
	// display: none;
}

.viability-quote {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 8px;
	padding: 16px;
	border: 1px solid #C0DAEE;
	width: 350px;
	.title {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #2F4858;
		display: flex;
		align-items: center;
		column-gap: 6px;
	
	}
	.percentage {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #748ea0;
	}
	.quote {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #2f4858;
	}
	.wrapper-quote {
		display: flex;
		justify-content: center;
		column-gap: 8px;
	}
	.savings {
		background: #effcfc;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		border-radius: 4px;
		padding: 24px;
		.title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			color: #2f4858;
		}
	}
	.price {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #02c3cd;
	}
}
.description-viability {
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #748ea0;
	margin-top: 24px;
}
.offer-detail {
	display: flex;
	justify-content: center;
	margin-bottom: 32px;

	.wrapper {
		display: flex;
		column-gap: 24px;
		background: transparent;
		border-radius: 8px;
	}
}

.amounts-name-mortgage {
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	color: #2f4858;
}

.amounts-quantity {
	color: #02c3cd;
}

.modal-onboarding-amount {
	.ant-modal-footer {
		display: none !important;
	}
}

@media only screen and (max-width: 768px) {
	.viability-quote {
		padding: 16px;
		width: 100%;
		.savings {
			padding: 16px;
		}
		.title,
		.percentage {
			font-size: 12px;
			line-height: 16px;
		}

		.price {
			font-size: 12px;
		}
		.title-subrogation {
			font-size: 12px;
			line-height: 16px;
		}
		.sub-title-subrogation {
			margin-top: 0px;
		}
	}
	.amounts-name-mortgage {
		font-size: 14px;
	}
}

.Estimated-Viability-onboarding {
	display: flex;
	flex-direction: column;
}

.collapse-viability-onboarding {
	background: #ffffff;
	margin-top: 16px;
	margin-bottom: 8px;
	.ant-collapse-content-box {
		padding: 0px;
	}
	.ant-collapse-header {
		padding: 12px 0px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #2f4858;
	}
	.anticon {
		right: 0px !important;
		color: #748ea0;
	}
}

.gb-form-separate-16 {
	.ant-form-item {
		margin-bottom: 16px !important;
	}
}
.gb-form-separate-24 {
	.ant-form-item {
		margin-bottom: 24px !important;
	}
	.ant-select-arrow{
		margin-top: 1px !important;
	}
	
}

.explination-others-incomes {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
	margin-top: -16px;
	margin-bottom: 24px;
}
.explination-savings {
	color: #02afb8;
}

.title-subrogation {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #748ea0;
}

.savings-subrogation {
	background: #effcfc;
	border-radius: 4px;
	padding: 18px;
	margin-top: 8px;
}

.sub-title-subrogation {
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #748ea0;
}

.wrapper-description-onboarding {
	display: flex;
}

.tabs-onboarding-mortgage {
	.ant-tabs-nav {
		width: 100% !important;
		align-self: center;
	}
	.ant-tabs-ink-bar {
		height: 3px !important;
	}
	.ant-tabs-tab {
		display: block;
		flex: 1;
		text-align: center;
		font-size: 16px;
		width: 224px;
	}
	.ant-tabs-nav > div:nth-of-type(1) {
		width: 100% !important;
	}
}
.tabs-onboarding-mortgage-mobile {
	.ant-tabs-nav {
		width: 100% !important;
		align-self: center;
	}
	.ant-tabs-ink-bar {
		height: 3px !important;
	}
	.ant-tabs-tab {
		display: block;
		flex: 1;
		text-align: center;
		font-size: 16px;
	}
	.ant-tabs-nav > div:nth-of-type(1) {
		display: unset !important;
		width: 100% !important;
	}
}

.reviews-onboarding-styles {
	display: flex;
	margin: auto;
	width: 65%;
	align-items: center;
	margin-top: 32px;
	justify-content: space-evenly;
}
.reviews-onboarding-styles-mobile {
	display: flex;
	margin: auto;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	row-gap: 8px;
}

.title-reviews-onboarding-styles {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #748ea0;
}

.modal-additional-guarantee {
	.ant-modal-content {
		border-radius: 0px !important;
	}
	.ant-modal-footer {
		padding: 40px;
	}
}

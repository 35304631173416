/* @import '~antd/dist/antd.less'; */

.gb-loans-page-initial {
	height: 100vh;
	background-image: url('../../../../assets/loans/background.png');
	background-size: contain;
}

.gb-card-loans-page-initial {
	background-image: url('../../../../assets/loans/background-init.png');
	background-size: cover;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
}

.title-page {
	font-size: 16px;
	font-weight: 600;
	color: #2f4858;
}

.gb-card-compare1 {
	.ant-card-body {
		padding: 16px;
	}
	background-color: #f1f7f8;
}

.gb-card-compare2 {
	.ant-card-body {
		padding: 16px;
	}
	background-color: #effcfc;
}

.gb-alert {
	.ant-alert-description {
		display: block;
		width: auto !important;
	}
}
.gb-card-loan-init {
	.ant-card-body {
		padding: 12px !important;
		border: 1px solid #52acff !important;
		border-radius: 4px !important;
		background: #d4eaff !important;
	}
}
.gb-card-loan-init-update {
	.ant-card-body {
		padding: 12px !important;
		border: 1px solid #fbc14f !important;
		border-radius: 4px !important;
		background: #fef0d3 !important;
	}
}

.gb-card-loan-init-desktop {
	.ant-card-body {
		padding: 24px !important;
		border: 1px solid #52acff !important;
		border-radius: 4px !important;
		background: #d4eaff !important;
	}
}
.gb-card-loan-init-green-desktop {
	.ant-card-body {
		padding: 24px !important;
		border: 1px solid #7dd353 !important;
		border-radius: 4px !important;
		background: #dff4d4 !important;
	}
}

.gb-card-loan-init-green {
	.ant-card-body {
		padding: 16px !important;
		border: 1px solid #7dd353 !important;
		border-radius: 4px !important;
		background: #dff4d4 !important;
	}
}
.gb-card-loan-init-blue-data {
	.ant-card-body {
		padding: 16px !important;
		border: 1px solid #02c3cd !important;
		border-radius: 4px !important;
		background: #effcfc !important;
	}
}
.gb-card-loan-init-blue-data-desktop {
	.ant-card-body {
		padding: 24px !important;
		border: 1px solid #02c3cd !important;
		border-radius: 4px !important;
		background: #effcfc !important;
	}
}
.gb-card-loan-init-blue-data-no-better {
	.ant-card-body {
		padding: 16px !important;
		border: 1px solid #52acff !important;
		border-radius: 4px !important;
		background: #d4eaff !important;
	}
}

.text-limit {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.gcardMovile-12-padding {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-card-body {
		padding: 12px;
	}
}

.gb-card-loan-init-green-12px {
	.ant-card-body {
		padding: 12px !important;
		border: 1px solid #7dd353 !important;
		border-radius: 4px !important;
		background: #dff4d4 !important;
	}
}

.ant-alert-with-description {
	padding: 16px !important;
}

.slider-dashboard {
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	column-gap: 32px;
	width: 100%;
	padding: 4px;
}

.slider-dashboard::-webkit-scrollbar {
	height: 5px;
}
.slider-dashboard::-webkit-scrollbar-thumb {
	background-color: #02c3cd;
}

.title-slider {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
	margin: 24px 0px 17px;
}

.wrapper-slider {
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0px 54px;
}

.title-card-slider {
	color: #01929a;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
	height: 84px;
	margin: auto;
	display: flex;
	align-items: center;
}
.carousel-property {
	.slick-dots li.slick-active button {
		background: #02c3cd !important;
	}
	.slick-dots li {
		background: #f1f7f8;
	}
	.slick-dots-bottom {
		bottom: -48px;
		z-index: 1;
	}
}

.modal-footer-loan {
	.ant-modal-content {
		border-radius: 4px !important;
	}
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
		font-family: 'Inter';
		width: auto;
	}
	.ant-modal-header {
		padding: 40px 40px 8px;
		border-bottom: none;
		border-radius: 4px !important;
	}
	.ant-modal-body {
		padding: 0px 40px 50px;
	}
	.ant-modal-footer {
		display: none;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 20px;
		padding-right: 20px;
		font-size: 18px;
	}
}
.modal-footer-loan-desktop {
	.ant-modal-header {
		padding: 32px 32px 8px;
	}
	.ant-modal-body {
		padding: 0px 32px 50px;
	}
}

.modal-title-loan {
	color: #748ea0;
	font-size: 14px;
	line-height: 20px;
}

.container-slider {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.items-slider {
	display: flex;
	align-items: center;
	width: fit-content;
	animation: carouselAnim 50s infinite alternate linear;
	column-gap: 32px;
}

@media only screen and (max-width: 768px) {
	.items-slider {
		animation: carouselAnim 35s infinite alternate linear;
	}

	@keyframes carouselAnim {
		from {
			transform: translate(0, 0);
		}
		to {
			transform: translate(calc(-100% + (2 * 300px)));
		}
	}
}

@keyframes carouselAnim {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(calc(-100% + (5 * 300px)));
	}
}

.container-slider .faders {
	width: 100%;
	position: absolute;
	height: 100%;
}

.faders .left {
	background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
	width: 15%;
	height: 100%;
	position: absolute;
	z-index: 200;
}

.faders .right {
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 100% rgba(245, 245, 245, 1) 0%);
	width: 15%;
	height: 100%;
	position: absolute;
	z-index: 200;
}

.right {
	right: 0;
	background: linear-gradient(to left, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.tab-selected {
	padding: 6px 12px;
	gap: 10px;
	background: #02c3cd;
	font-weight: 600;
	font-size: 14px;
	color: #ffffff;
	border-radius: 4px;
	cursor: pointer;
}
.tab-not-selected {
	padding: 6px 12px;
	gap: 10px;
	font-weight: 400;
	font-size: 14px;
	color: #02c3cd;
	border-radius: 4px;
	cursor: pointer;
}
.title-loan-form {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #02c3cd;
}
.date-picker-loan {
	width: 100%;
	height: 40px;
	border: 1px solid #c0daee;
}

.modal-without-buttons-loans {
	.ant-modal-header {
		padding: 40px 40px 8px;
		border-bottom: none;
	}
	.ant-modal-body {
		padding: 0px 40px 40px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #748ea0;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-footer {
		display: none;
	}
	.ant-modal-close-x {
		padding-top: 24px;
		padding-right: 40px !important;
		color: #02c3cd;
	}
}

.interest-actual {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #02c3cd;
}
.icons-style {
	font-size: 20px;
	color: #748ea0;
	cursor: pointer;
}

.wrapper-quota {
	justify-content: center;
	font-size: 48px;
	line-height: 48px;
	color: #02c3cd;
	font-weight: 700;
	align-items: center;
	row-gap: 5px;
}
.term-widget-text {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
}
.term-widget-data {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}
.wrapper-leggend-loan {
	display: flex;
	align-items: center;
	column-gap: 8px;
}
.dot-loan {
	width: 12px;
	height: 12px;
	background: #41d2da;
}
.text-leggend-loan {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
}
.pie-wrapper-loan {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.card-heigth-loan {
	.ant-card-body {
		height: 100%;
	}
}

.card-tips-loan {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 14px;
}

.calculators-widget-title {
	font-family: 'Inter';
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
}
.calculators-widget-subtitle {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #748ea0;
}

.wrapper-type-calculator {
	background: #effcfc;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	padding: 18px;
	// margin-top: 8px;
	align-items: center;
	cursor: pointer;
	.text {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #2f4858;
	}
}

.title-initial-information {
	font-family: 'Inter';
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #2f4858;
}

.init-card-calculators {
	.ant-card-body {
		height: 100%;
	}
}

.init-card-24padding {
	background-color: #ffffff;
	.ant-card-body {
		padding: 24px;
	}
}

.scroll-layout-means-sources {
	overflow-x: scroll !important;
}
.scroll-layout-means-sources::-webkit-scrollbar {
	height: 0.1px;
}
.scroll-layout-means-sources::-webkit-scrollbar-track {
	background: transparent;
}
.scroll-layout-means-sources::-webkit-scrollbar-thumb {
	background: transparent;
}

.newSubmenusTabsMobile {
	display: flex;
	background-color: #effcfc;
	margin-top: 16px;
	margin-left: 16px;
	border-radius: 5px;
	margin-bottom: 16px;
	border-bottom: none;
}

.input-months {
	.ant-input-number-input-wrap {
		input {
			height: 38px !important;
		}
	}
}

.gibobs-dashboard-inmo {
	margin-left: 40px;
	width: calc(100% - 40px);
	margin-bottom: 100px;

	.gibobs-dashboard-inmo-operations {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8px;
	}

	.box-double {
		width: auto;
		height: 300px;
		// margin-right: 24px;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
		border-radius: 4px;
		cursor: pointer;

		.box-subtext {
			font-weight: bold;
			font-size: 16px;
			color: #2f4858;
			width: 206px;
			line-height: 24px;
			margin-bottom: 30px;
		}
	}

	.box-inmo {
		width: 100%;
		height: auto;
		// margin-right: 24px;
		margin-left: 0;
		cursor: pointer;
		border-radius: 4px;

		.ant-card-body {
			padding: 14px !important;
		}

		.box-text {
			font-weight: 600;
			font-size: 32px;
			height: 40px;
			line-height: 40px;
			color: #ffffff;
		}

		.box-subtext {
			font-weight: 600;
			font-size: 16px;
			color: #ffffff;
			// width: 244px;
			height: 24px;
		}
	}

	.box-inmo-mobile {
		width: 100%;
		height: 69px;
		margin-bottom: 8px;
		cursor: pointer;
		border-radius: 4px;

		.ant-card-body {
			padding: 16px;
		}

		.box-text {
			font-weight: 700;
			font-size: 32px;
			height: 40px;
			line-height: 40px;
			color: #ffffff;
		}

		.box-subtext {
			font-weight: 600;
			font-size: 16px;
			color: #ffffff;
			// width: 296px;
			height: 24px;
		}
	}
}

.gibobs-widget-info-inmo {
	.ant-card-body {
		padding: 24px !important;
	}

	margin-bottom: 24px;
	height: 330px;
	width: 308px;
	margin-right: 24px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
}

.gibobs-widget-info-inmo-mobile {
	margin-bottom: 16px;
	background-color: #ffffff;
	width: 100%;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 24px;
	}
}

.gibobs-widget-products-inmo {
	background-color: #ffffff;
	height: 330px;
	width: 308px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 24px;
	}
}

.gibobs-widget-products-inmo-mobile {
	background-color: #ffffff;
	height: auto;
	width: calc(100% - 16px);
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;

	.ant-card-body {
		padding: 16px;
	}
}

.button-cards-new-styles {
	font-size: 14px;
	width: 244px;
	height: 40px;
	position: absolute;
}

.title-cards {
	font-size: 16px;
	font-weight: 600;
	color: #2f4858;
}

.description-cards {
	font-size: 14px;
	color: #748ea0;
	margin-top: 8px;
	font-weight: 400;
}

.style-colunms-left {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.modal-mobile-basic {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 32px;
		padding-top: 32px;
		padding-bottom: 8px;
		padding-right: 32px;
	}

	.ant-modal-body {
		padding: 0 32px 32px;
		font-size: 14px;
		color: #748ea0;
	}

	.ant-modal-footer {
		padding: 0 32px 32px;
		border-top: none;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 12px;
		padding-right: 28px;
	}
}

.modal-basic {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding: 40px 40px 8px 40px;
	}

	.ant-modal-body {
		padding: 0px 40px 38px 40px;
		font-size: 14px;
		color: #748ea0;
	}

	.ant-modal-footer {
		padding: 0px 40px 40px;
		border-top: none;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.gb-form-inmo {
	.ant-form-item {
		margin-bottom: 16px;

		.ant-form-item-label {
			line-height: 16px;

			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
			}
		}

		.ant-form-item-control {
			input {
				height: 40px;
				color: #748ea0;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
			}

			.ant-select-selector {
				height: 40px;
				width: 100%;

				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #748ea0;
				}

				.ant-select-selection-placeholder {
					height: 40px;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}

.typography-titles {
	font-size: 16px;
	font-weight: 600;
	color: #2f4858;
}

.typography-blue-text {
	font-size: 16px;
	font-weight: normal;
	color: #02c3cd;
}

.gibobs-steps-new-style {
	overflow-y: scroll;

	.ant-steps-item-description {
		margin-left: 0;
		margin-top: 6px;
		width: auto;
	}

	.ant-steps-item-icon {
		width: 24px !important;
		height: 24px !important;
		background: white;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #02c3cd;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #c0daee;
				}
			}
		}

		.ant-steps-item-icon {
			background: white;
		}
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-tail {
				color: red;
				display: none;
			}

			.ant-steps-item-content {
				.ant-steps-item-title {
					margin-top: 0;
					font-size: 16px;
					color: #2f4858;
				}
			}
		}

		.ant-steps-item-icon {
			border-color: #52c41a;
			background-color: #ffffff;

			.ant-steps-icon {
				top: -1.5px;
				display: inline;
				color: #52c41a;
			}
		}
	}
}

.gibobs-steps-new-style::-webkit-scrollbar {
	width: 12px;
	background-color: transparent;
}

.gibobs-steps-new-style::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 50px;
	background-color: transparent;
}

.gibobs-card-styles {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
}

.gibobs-steps-new-style-mobile {
	.ant-steps-item-description {
		width: 260px;
	}
}

.gb-steps-styles-common {
	// margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
	}

	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;

			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}

			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 4px solid #02c3cd;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				text-align: center !important;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
				}
			}
		}
	}
}

.steps-styles-banks {
	.ant-steps-item-finish {
		.ant-steps-item-icon {
			border-color: #02c3cd;
			border-width: 1px;

			.ant-steps-icon {
				top: -0.5px;
				display: inherit;
				color: #02c3cd;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #02c3cd;
				}
			}
		}
	}

	.ant-steps-item-error {
		.ant-steps-item-icon {
			border-color: red;
			border-width: 1px;

			.ant-steps-icon {
				top: -0.5px;
				display: inherit;
				color: red;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: red;
				}
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			.ant-steps-icon {
				display: none;
			}
		}
	}

	.ant-steps-item-description {
		margin-left: -48px;
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				border-width: 1px !important;
			}
		}
	}
}

.steps-banks {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-tail {
		margin-left: -8px;
	}

	.ant-steps-item-container {
		.ant-steps-item-icon {
			margin-left: 24px;
		}
	}

	.ant-steps-item-description {
		width: 80px;
		text-align: center !important;
		font-size: 10px;
		margin-top: 35px;
	}
}

.steps-banks-fein {
	.ant-steps-item-container {
		.ant-steps-item-icon {
			margin-left: 18px;
		}
	}

	.ant-steps-item-description {
		margin-left: -80px !important;
		width: 140px !important;
	}
}

.step-date {
	color: #748ea0;
	font-size: 12px;
	font-weight: 400;
}

.menu-tools {
	.ant-menu {
		.ant-menu-item-selected:after {
			display: none;
		}
	}
}

.gb-form-common-new-style-32 {
	.ant-form-item {
		margin-bottom: 24px !important;
	}
}

.modal-confirm {
	.ant-modal-body {
		padding-top: 32px !important;
	}
}

.modal-contact-me-inmo {
	.ant-modal-content {
		bottom: 90px !important;
	}
}

.button-primary-gibobs-eco {
	background-color: #72d00a;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	font-weight: 600;
	width: auto;
}

.button-primary-gibobs-eco:hover {
	background-color: rgba(114, 208, 10, 0.8);
}

.card-inmo-account {
	.ant-card-body {
		padding: 24px;
	}
}

.tabs-inmo {
	.ant-tabs-tab {
		font-size: 16px;

		.ant-tabs-tab-active {
			font-weight: 600;
		}
	}
}

.gb-form-inmo-scroll {
	height: auto;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-bottom: 100px;
}

// .gb-form-inmo-scroll::-webkit-scrollbar {
//   width: 2px;
//   background-color: transparent;
// }
// .gb-form-inmo-scroll::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   height: 50px;
//   background-color: transparent;
// }

.modal-apply-for-inmo {

	.ant-modal-body {
		padding: 32px !important;

		.ant-btn {
			border-radius: 0px;
			background-color: #02c3cd;
			width: 100%;
		}

		.ant-btn:hover {
			background-color: #4fc9d1;
		}
	}
}